body{

  // Buttons

  .ant-btn{
    font-weight: bold;
    transition: all 0.3s linear;
    backface-visibility: hidden;
    letter-spacing: 0;
    height: auto;
    padding: 6px 12px;
    

    &.ant-btn-lg{
      padding: 12px 15px;
      font-size: 16px;
      border-radius: 4px;
      line-height: 1;
      height: auto;
    }

    &.ant-btn-primary{
      color: #fff;
      background-color: $primary;
      border-color: $primary;
      text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
      box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);

      @include hover-focus-active{
        border-color: darken($primary, 5);
        background: darken($primary, 5);
        box-shadow: 0px 10px 10px -10px rgba($primary, 0.4);
      }
    }

    &.ant-btn-link{
      border: none;
      background: none;
      color: $gray-900;
      
      @include hover-focus-active{
        border: none;
        background: none;
      }
    }
  }

  // Inputs

  .ant-form-item{
    position: relative;

    .has-error .ant-form-explain, .has-error .ant-form-split{
      text-align: left;
      color: $primary;
      position: absolute;
      left: 0;
      font-size: 12px;
      bottom: -20px;
    }

  }

  .ant-input {
    border: solid 1px $gray-400;

    @include hover-focus-active{
      border: solid 1px $blue;
      box-shadow: 0 0 0 2px rgba($blue, 0.2);
    }
   
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: solid 1px $gray-400;
    -webkit-text-fill-color: $black;
    // -webkit-box-shadow: 0 0 0px 1000px $white inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  // Ant Dropdown

  .ant-dropdown-menu-item:hover,
  .ant-dropdown-menu-submenu-title:hover{
    background: rgba($primary, 0.05);
  }

  // Ant Breadcrumbs

  .ant-breadcrumb{
    background: #fff;
    padding: 10px 40px;
    box-shadow:0 0px 8px $gray-200;

    a:hover{
      color: $primary;
    }
  }

  // Ant Table

  .ant-table{

    &-thead{
      font-weight: bold;
      font-size: 14px;
      text-transform: uppercase;
      
      & tr > th{
        padding: 10px 16px;
        font-weight: 600 !important;
        font-size: 13px;
        color: #212121;
      }
    }

    &-body{

      tr:hover td{
        background: rgba($primary, 0.05) !important;
      }

      td.numbers{
        font-size: 14px;
      }

      td{
        color: $gray-700;
        padding: 20px 16px;
      }
    }
  }

  // React Table

  .ReactTable{
    border: none;

    &.-striped .rt-tr.-odd{
      background: #fff;
    }

    .rt-thead.-header{
      box-shadow: none;
    }

    .rt-thead{

      .rt-th, .rt-td{
        background: #fafafa;
        border-bottom: 1px solid #e8e8e8;
        border-right: none;
        box-shadow: none;
        padding: 16px 5px;
        font-weight: 500 !important;
      }
    }

    .rt-td{
      font-size: 14px;
      padding: 10px;
    }

    .rt-tbody .rt-tr-group{
      border-bottom: 1px solid #e8e8e8;

      // &:last-child .rt-td{
      //   border-bottom: none;
      // }

      .rt-td{
        border-right: none;
      }

      .rt-tr{
        display: flex;
        align-items: center;
      }
    }

    .rt-thead .rt-th.-sort-asc,
    .rt-thead .rt-td.-sort-asc{
      box-shadow: inset 0 3px 0 0 rgba( $primary, 0.6 );
    }

    .rt-thead .rt-th.-sort-desc,
    .rt-thead .rt-td.-sort-desc{
      box-shadow: inset 0 -3px 0 0 rgba( $primary, 0.6 );
    }

    div[contenteditable="true"]{
      background: #fff !important;
      border-radius: 4px;
      border: solid 1px #fff;
      margin: 0 5px;
      padding: 5px 0;

      &:focus{
        outline: none;
        border: solid 1px $info;
      }
    }

    &.-highlight .rt-tbody .rt-tr:not(.-padRow):hover{
      background: rgba($primary, 0.05) !important;
    }

    // .-loading{
    //   all: unset;
    //   position: relative;
    //   background: rgba(#fff, 0.8);
    //   height: 0;
    //   display: none;

    //   & > div{
    //     width: auto;
    //     top: 0;
    //     right: 0; 
    //     bottom: 0;
    //     left: 0;
    //     margin: auto;
    //   }

    //   &.-active{
    //     min-height: 300px;
    //     margin-top: -40px;
    //     display: block;

    //     & > div{
    //       transform: translateY(0);
    //       height: 20px;
    //     }
    //   }
    // }
  }

  // Ant Card

  .ant-card{
    border-radius: 6px;
    box-shadow: 0 2px 8px 0 #E0E0E0;
  }

  // Ant Steps

  .ant-steps{
    max-width: 80%;
    margin: 0 auto;

    &-item{
      position: relative;

      &-icon{
        display: none;
      }

      &-content{
        text-align: center;
        position: relative;
      }

      &-title{
        font-size: 14px;
        color: #616161;
        position: unset;

        &:after{
          left: 110%;
          top: 0; bottom: 0;
          margin: auto;
        }
      }

      &-description{
        font-weight: bold;
        font-size: 18px;
        color: #212121;
        text-align: center;
      }
    }

    .ant-steps-item-wait > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
      color: $gray-700;
    }

    .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-description{
      color: $primary;
    }

    .ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
      background: $primary;
    }
  }


}

