/* Keyframes */

@-webkit-keyframes loading {
  0%,
  100% {
    -webkit-transform: scale(0);
    opacity: 0;
  }
  50% {
    -webkit-transform: scale(1);
    opacity: 1;
  }
}

@-moz-keyframes loading {
  0%,
  100% {
    -moz-transform: scale(0);
  }
  50% {
    -moz-transform: scale(1);
  }
}

@-o-keyframes loading {
  0%,
  100% {
    -o-transform: scale(0);
  }
  50% {
    -o-transform: scale(1);
  }
}

@keyframes loading {
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
}
.load-wrap {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 30em;
  height: 18em;
  .circle {
    &:nth-child(1) {
      -webkit-animation-delay: 0.1s;
      -moz-animation-delay: 0.1s;
      -o-animation-delay: 0.1s;
      animation-delay: 0.1s;
    }
    &:nth-child(2) {
      -webkit-animation-delay: 0.3s;
      -moz-animation-delay: 0.3s;
      -o-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      -webkit-animation-delay: 0.5s;
      -moz-animation-delay: 0.5s;
      -o-animation-delay: 0.5s;
      animation-delay: 0.5s;
    }
    &:nth-child(4) {
      -webkit-animation-delay: 0.7s;
      -moz-animation-delay: 0.7s;
      -o-animation-delay: 0.7s;
      animation-delay: 0.7s;
    }
  }
}
.loadingcircle {
  display: inline-block;
  height: 20px;
  width: 20px;
  margin-right: 10px;
  background-color: $red;
  border-radius: 50%;
  -webkit-animation: loading 1.6s infinite;
  -moz-animation: loading 1.6s infinite;
  -o-animation: loading 1.6s infinite;
  animation: loading 1.6s infinite;
}
