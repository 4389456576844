// Bg

  @each $color, $value in $theme-colors {
    .bg-#{$color} {
      background: $value !important;
    }
  }


body{

  .notification-dropdown{
    
    .ant-dropdown-menu{
      margin-top: 15px;
      position: relative;

      &:before{
        position: absolute;
        top: -8px;
        right: 0;
        left: 0;
        margin: auto;
        @include triangle(top, $white, 8px)
      }
    }
  }

  .ant-popover-inner-content .help-text{
    max-width: 460px;

    h4{
      font-size: 16px;
    }

    ul{
      margin: 0;
      padding: 0;

      li{
        list-style: none;
        position: relative;
        padding-left: 30px;
        margin-bottom: 20px;

        &:before{
          content:"";
          display: block;
          position: absolute;
          top: 10px;
          left: 0;
          width: 15px;
          height: 2px;
          background: rgba(0, 0, 0, 0.65);
        }
      }
    }
  }
}