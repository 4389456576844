body {
  background: #eeeeee;
  // background-image: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);

  #root .ant-layout {
    background: none;
  }
}

a {
  color: $primary;

  @include hover-focus-active {
    color: darken($primary, 5);
  }
}

.outer-wrapper {
  margin: 15px 16px 24px;
  padding: 24px;
  min-height: 300px;

  &.card {
    border: 1px solid #e8e8e8;
    background: #fff;
    border-radius: 5px;
  }
}

body .main-header {
  background: $primary;
  padding: 0 40px;

  .ant-menu {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;

    &.ant-menu-horizontal > .ant-menu-item {
      border: none !important;
    }

    li {
      color: rgba($white, 0.9);
      font-weight: 800;
      text-transform: uppercase;
      padding: 0 15px;
      font-size: 15px;
      color: #ffffff;
      border: none;

      a {
        color: rgba($white, 0.9);
        border: none;

        @include hover-focus-active {
          color: $white;
          border: none;
        }
      }

      i {
        color: rgba($white, 0.9);
      }

      @include hover-focus-active {
        i {
          color: rgba($white, 1);
        }
      }
    }
  }

  .creat-btn {
    background: rgba($white, 0.4);
    border: none;
    color: $white;
    padding: 0 15px;
    line-height: 36px;
    border-radius: 5px;
    font-weight: 700;
    transition: all 0.3s linear;

    @include hover-focus-active {
      background: #fff;
      color: $primary !important;
    }
  }
}

.notification-dropdown {
  ul {
    min-width: 250px;
  }

  ul li {
    padding: 15px 10px;
    border-bottom: solid 1px $gray-400;

    &:last-child {
      border-bottom: none;
    }

    span.date {
      display: block;
    }
  }
}

.session-card {
  background: #ffffff;
  border: 1px solid $gray-400;
  border-radius: 4px;
  padding: 20px 20px 10px 20px;
  transition: all 0.3s linear;

  &:hover {
    box-shadow: 0 2px 10px 1px $gray-300;
    transform: translateY(-4px);
  }

  &-title {
    font-size: 18px;
    color: $gray-900;
  }

  &-desc {
    font-size: 14px;
    color: $gray-600;
    line-height: 20px;
    margin-bottom: 25px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &-data {
    border-top: solid 1px $gray-300;
    border-bottom: solid 1px $gray-300;
    padding: 10px 0;
    margin-bottom: 15px;

    div {
      text-align: center;
      width: 60%;
      padding: 15px 0;

      &:first-child {
        border-right: solid 1px $gray-300;
        width: 40%;
      }
    }

    span {
      display: block;
    }

    .number {
      font-size: 15px;
      color: #ff6720;
      text-align: center;
      font-weight: bold;
    }

    .text {
      font-size: 14px;
      color: #9e9e9e;
      text-align: center;
    }
  }

  &-meta {
    font-size: 13px;

    a {
      font-weight: bold;
      font-size: 14px;
    }
  }
}

.session-title {
  margin-bottom: 0;

  span {
    margin-top: 5px;
    display: block;
    font-size: 13px;
    color: $gray-600;
    letter-spacing: 0;
    font-weight: normal;
  }
}

.session-count {
  padding: 4px 5px;
  border-radius: 4px;
  color: #fff;
}

.text-danger .session-count {
  background: $primary;
}

.text-success .session-count {
  background: $success;
}

.text-dark .session-count {
  background: #fff;
  border: solid 1px $gray-400;
  color: #343a40;
}

.preview-form {
  input {
    pointer-events: none;
    border: none;
    font-weight: bold;
    color: $gray-900;
    padding: 0;
  }
}

.terminal {
  background-color: black;
  height: 100vh;
  margin: 0;
  overflow: hidden;
  padding: 2rem;
  color: white;
  font: monospace;
  position: relative;
}

.terminal::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: repeating-linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.15),
    rgba(0, 0, 0, 0.15) 1px,
    transparent 1px,
    transparent 2px
  );
  pointer-events: none;
}

::selection {
  background: #0080ff;
  text-shadow: none;
}

pre {
  margin: 0;
}

.preview {
  margin-bottom: 24px;

  span {
    display: block;
    overflow-wrap: break-word;
  }

  .title {
    display: inline-block;
    overflow: hidden;
    line-height: 39.9999px;
    white-space: nowrap;
    text-align: right;
    vertical-align: middle;
  }

  .value {
    font-weight: bold;
    font-size: 16px;
    color: #212121;
    letter-spacing: 0;
    line-height: 1.8;
  }
}

.bg-white {
  background: #fff !important;
}

.bg-gray {
  background-color: $gray-600 !important;
}

.custom-radio {
  .ant-radio-button-wrapper {
    font-weight: 600;
    padding-left: 40px;

    &:before {
      content: '';
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      left: 10px;
      top: 0;
      bottom: 0;
      margin: auto;
      background: #ffffff;
      border: 1px solid #bdbdbd;
      box-shadow: 0 2px 4px 0 #e0e0e0;
      border-radius: 4px;
    }

    &:not(.ant-radio-button-wrapper-disabled)::before {
      background: #fff !important;
      opacity: 0.5;
    }

    .ant-radio-button {
      &:before {
        content: '\2713';
        position: absolute;
        left: 14px;
        font-size: 14px;
        opacity: 0;
      }

      &-checked:before {
        opacity: 1;
      }
    }
  }

  .ant-radio-button-wrapper:hover {
    color: $success;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    border-color: $success;
    color: $success;
    box-shadow: -1px 0 0 0 $success;

    &:before {
      background: #ffffff;
      border: 1px solid #86d295;
      box-shadow: 0 2px 4px 0 rgba(134, 210, 149, 0.3);
      border-radius: 4px;
      opacity: 1;
    }
  }

  .ant-radio-group {
    width: 100%;

    .ant-radio-button-wrapper {
      width: 50%;
    }
  }

  &.moo .ant-form-item-label > label {
    color: $info;
  }

  &.ibc .ant-form-item-label > label {
    color: $secondary;
  }
}
